"use strict";

(function () {
  class ManageRepairModalCtrl {
    constructor(
      $scope,
      $uibModalInstance,
      $state,
      Upload,
      Image,
      Finding,
      Repair,
      Blade,
      options,
      usSpinnerService,
      toastr,
      Util,
      Auth,
      $timeout,
      ActivityLog
    ) {
      this.Util = Util;
      this.Blade = Blade;
      this.Finding = Finding;
      this.Repair = Repair;
      this.Upload = Upload;
      this.Image = Image;
      this.toastr = toastr;
      this.usSpinnerService = usSpinnerService;
      this.$uibModalInstance = $uibModalInstance;
      this.isOrganizationAdmin = Auth.isOrganizationAdmin;
      this.isOrganizationUserPlus = Auth.isOrganizationUserPlus;
      this.$timeout = $timeout;
      this.isDisabled = false;
      this.errors = {};
      this.repairDatePopup = {
        opened: false,
      };
      this.options = options;
      this.ActivityLog = ActivityLog;
      console.log("options :", this.options);
      this.submitted = false;
      Auth.isLoggedInAsync((loggedIn) => {
        if (loggedIn) {
          this.currentUser = Auth.getCurrentUser();
        } else {
          $state.go("login");
        }
      });

      this.materialDropdown = [
        "material1",
        "material2",
        "material3",
        "material4",
      ];

      this.defectProcedures = [
        {
          name: "Aesthetic",
          conditions: [
            { name: "Abrade Surface", materialsUsed: [] },
            { name: "Coating Applied", materialsUsed: [] },
          ],
        },
        {
          name: "Basic - Fill and Sand",
          conditions: [
            { name: "Abrade Surface", materialsUsed: [] },
            { name: "Fill and Sand", materialsUsed: [] },
            { name: "Coating Applied", materialsUsed: [] },
          ],
        },
        {
          name: "Adhesive Repair",
          conditions: [
            { name: "Abrade or Grind Surrounding Material", materialsUsed: [] },
            { name: "Laminate and or Apply Adhesive Cure", materialsUsed: [] },
            { name: "Abrade Surface", materialsUsed: [] },
            { name: "Fill and Sand", materialsUsed: [] },
            { name: "Coating Applied", materialsUsed: [] },
          ],
        },
        {
          name: "Laminate Repair",
          conditions: [
            { name: "Abrade or Grind Surrounding Material", materialsUsed: [] },
            { name: "Laminate and or Apply Adhesive Cure", materialsUsed: [] },
            { name: "Abrade Surface", materialsUsed: [] },
            { name: "Fill and Sand", materialsUsed: [] },
            { name: "Coating Applied", materialsUsed: [] },
          ],
        },
        {
          name: "Core Repair",
          conditions: [
            { name: "Removed damaged core", materialsUsed: [] },
            { name: "Core replaced", materialsUsed: [] },
            { name: "Laminate applied and cured", materialsUsed: [] },
            { name: "Abrade surface Coating applied", materialsUsed: [] },
            { name: "Fill and Sand", materialsUsed: [] },
            { name: "Coating Applied", materialsUsed: [] },
          ],
        },
        {
          name: "Other Lighting Strip plug holes VGs etc",
          conditions: [
            { name: "Abrade Surface", materialsUsed: [] },
            { name: "Repair Complete", materialsUsed: [] },
            { name: "Coating Applied", materialsUsed: [] },
          ],
        },
        {
          name: "Investigation or Temporary",
          conditions: [
            { name: "Abrade Surface", materialsUsed: [] },
            { name: "Fill and Sand", materialsUsed: [] },
            { name: "Coating Applied", materialsUsed: [] },
          ],
        },
        {
          name: "Elle Shells Repair",
          conditions: [
            { name: "Abrade Surface", materialsUsed: [] },
            { name: "Fill and Sand", materialsUsed: [] },
          ],
        },
      ];

      this.repairLocations = [
        "Leading Edge",
        "Pressure Side",
        "Suction Side",
        "LE Main Shear Web",
        "TE Main Shear Web",
        "TE Shear Web if exists",
        "Trailing Edge",
      ];

      this.chordDistances = [
        "0%",
        "0 - 25%",
        "25 - 50%",
        "50 - 75%",
        "75 - 100%",
        "100%",
      ];

      if (this.options.repair && this.options.repair._id) {
        this.repair = {};
        _.each(this.options.repair, (val, key) => {
          if (!(key in ["blade", "turbine", "finding"])) {
            if (key === "date") {
              this.repair[key] = val ? new Date(val) : null;
            } else {
              this.repair[key] = val;
            }
          }
        });

        this.getBladeFindings(this.repair.turbine._id, this.repair.blade._id);
        // console.log('Edit mode repair: ', this.repair);
        // this.repair = _.omit(this.options.repair, ['blade', 'turbine', 'finding']);
        this.repair.blade = this.options.repair.blade._id;
        this.repair.turbine = this.options.repair.turbine._id;
        this.repair.finding = this.options.repair.finding
          ? this.options.repair.finding._id
          : undefined;
        this.selectedFinding = this.options.repair.finding;
        this.repair.workOrder = this.options.repair.workOrder
          ? this.options.repair.workOrder._id
          : undefined;

        for (let i = 1; i <= 12; i++) {
          for (let j = 1; j <= 4; j++) {
            if (
              this.repair.elleApplication &&
              this.repair.elleApplication[`shellPosition${i}`] &&
              this.repair.elleApplication[`shellPosition${i}`][
                `shellPositionImage${j}`
              ]
            ) {
              this.repair.elleApplication[`shellPosition${i}`][
                `shellPositionDisplayImage${j}`
              ] =
                this.repair.elleApplication[`shellPosition${i}`][
                  `shellPositionImage${j}`
                ];
            }
          }
        }
      } else {
        this.repair = {
          blade: this.options.blade._id,
          turbine: this.options.blade.turbine._id,
          woId: null,
          date: new Date(),
          closeUpImages: [],
          images: [],
          defectProcedure: null,
        };
        this.repair.elleApplication = {};
        for (let i = 1; i <= 12; i++) {
          this.repair.elleApplication[`shellPosition${i}`] = {
            shellPosition: "",
            shellSerialNumber: "",
            temperatureOnApplication: "",
            humidityOnApplication: "",
            batchNumberOfAdhesionPromoter: "",
            batchNumberOfSealant: "",
            shellPositionImage1: "",
            shellPositionImage2: "",
            shellPositionImage3: "",
            shellPositionImage4: "",
          };
        }
        console.log("init repair", this.repair);
      }

      if (this.repair.images && this.repair.images.length > 0) {
        let repairImage = _.find(this.repair.images, { isPrimary: true });
        if (repairImage && repairImage.path) {
          this.displayRepairPrimaryImage = repairImage.path;
        } else {
          for (let index = 0; index < this.repair.images.length; index++) {
            repairImage = this.repair.images[index];
            if (repairImage.path) {
              this.displayRepairPrimaryImage = repairImage.path;
              break;
            }
          }
        }
      }

      $scope.$on("$stateChangeStart", function () {
        $uibModalInstance.dismiss();
      });

      $(document).bind("click", function () {
        $(".multiple-select-wrapper .list").slideUp();
      });
    }

    repairShellImageSelected(index, imageIndex) {
      if (
        this.repair.elleApplication[`shellPosition${index}`][
          `shellPositionImage${imageIndex}`
        ]
      ) {
        this.isDisabled = true;
        this.repair.elleApplication[`shellPosition${index}`][
          `shellPositionImage${imageIndex}`
        ].shellPositionIndex = index;
        this.repair.elleApplication[`shellPosition${index}`][
          `shellPositionImage${imageIndex}`
        ].shellPositionImageIndex = imageIndex;
        this.repair.elleApplication[`shellPosition${index}`][
          `shellPositionImage${imageIndex}`
        ].repairType = "ShellPosition";

        let allImages =
          this.repair.elleApplication[`shellPosition${index}`][
            `shellPositionImage${imageIndex}`
          ];

        this.startImageUpload([allImages], (err, result) => {
          if (!err) {
            this.isDisabled = false;
            console.log(
              this.repair.elleApplication[`shellPosition${index}`],
              "shellPosition"
            );
          } else {
            this.toastr.error("Image upload failed.");
          }
        });
        return;
      }
    }

    conditionImagesSelected(condition) {
      _.each(condition.ngfImages, (image) => {
        image.repairType = "CONDITION";
        image.conditionName = condition.name;
      });
      this.isDisabled = true;
      console.log("condition.ngfImages: ", condition.ngfImages);
      this.startImageUpload(condition.ngfImages, (err, result) => {
        if (!err) {
          this.isDisabled = false;
        } else {
          this.toastr.error("Image upload failed.");
        }
      });
      return;
    }

    repairImagesSelected(type, form) {
      if (type === "REPAIR") {
        _.each(this.repair.ngfImages, (image) => {
          image.repairType = type;
        });
        this.isDisabled = true;
        this.startImageUpload(this.repair.ngfImages, (err, result) => {
          if (!err) {
            this.isDisabled = false;
          } else {
            this.toastr.error("Image upload failed.");
          }
        });
        return;
      } else if (type === "CLOSE_UP") {
        _.each(this.repair.ngfCloseUpImages, (image) => {
          image.repairType = type;
        });
        this.startImageUpload(this.repair.ngfCloseUpImages, (err, result) => {
          if (!err) {
          } else {
            this.toastr.error("Image upload failed.");
          }
        });
        return;
      }
    }

    save(form) {
      this.submitted = true;
      this.serverError = null;
      if (!form.$valid) {
        this.toastr.error("Form is invalid.");
        return;
      }
      // this.isDisabled = true;
      this.startSpin("spinner-1");
      console.log("in save repair: ", this.repair);
      if (this.options.repair && this.options.repair._id) {
        this.updateRepair(form);
      } else {
        this.createRepair(form);
      }
      // check if images exists
      // let allImages = _.compact(
      //   _.concat(this.repair.ngfImages, this.repair.ngfCloseUpImages)
      // );
      // _.each(this.repair.defectProcedure.conditions, (condition) => {
      //   if (condition.ngfImages && condition.ngfImages.length > 0) {
      //     allImages = _.concat(allImages, condition.ngfImages);
      //   }
      // });
      // for (let i = 1; i <= 12; i++) {
      //   for (let j = 1; j <= 4; j++) {
      //     if (
      //       this.repair.elleApplication[`shellPosition${i}`][
      //         `shellPositionImage${j}`
      //       ] &&
      //       this.repair.elleApplication[`shellPosition${i}`][
      //         `shellPositionImage${j}`
      //       ].name
      //     ) {
      //       allImages = _.concat(
      //         allImages,
      //         this.repair.elleApplication[`shellPosition${i}`][
      //           `shellPositionImage${j}`
      //         ]
      //       );
      //     }
      //   }
      // }
      // console.log("all repair images: ", allImages);
      // this.startImageUpload(allImages, (err, result) => {
      //   if (!err) {
      //     if (this.options.repair && this.options.repair._id) {
      //       this.updateRepair(form);
      //     } else {
      //       this.createRepair(form);
      //     }
      //   } else {
      //     this.toastr.error("Image upload failed.");
      //   }
      // });
      // return;
      // }
    }

    updateRepair(form) {
      this.Repair.update(this.repair)
        .$promise.then((repair) => {
          this.stopSpin("spinner-1");
          console.log("update repair result: ", repair);
          let uniqueReferenceNumber = "NA";
          if (
            this.options.repair.finding &&
            this.options.repair.finding.uniqueReferenceNumber
          ) {
            uniqueReferenceNumber = `<a href="/blade/${this.options.repair.blade._id}/finding/${repair.data.finding}">${this.options.repair.finding.uniqueReferenceNumber}</a>`;
          }
          this.bladeStr = "";
          if (this.options.repair.blade.make) {
            this.bladeStr += this.options.repair.blade.make + " ";
          }
          if (this.options.repair.blade.model) {
            this.bladeStr += this.options.repair.blade.model;
          }
          if (this.options.repair.blade.name) {
            this.bladeStr += this.options.repair.blade.name;
          }

          this.turbineStr = "";
          if (this.options.repair.turbine.make) {
            this.turbineStr += this.options.repair.turbine.make;
          }
          if (this.options.repair.turbine.model) {
            this.turbineStr += this.options.repair.turbine.model;
          }
          if (this.options.repair.turbine.name) {
            this.turbineStr += this.options.repair.turbine.name;
          }
          const activityLogData = {
            site: this.options.repair.turbine.site._id,
            turbine: this.options.repair.turbine._id,
            blade: this.options.repair.blade._id,
            finding: repair.data.finding,
            repair: repair.data._id,
            user: this.currentUser._id,
            source: "web",
            action: "edit",
            type: "repair",
            sourceScreen: "repair",
            eventString: `Repair work order <a href="/repair/${repair.data._id}">${repair.data.woId}</a> was edited for ${uniqueReferenceNumber} in wind farm <a href="/site/${this.options.repair.turbine.site._id}">${this.options.repair.turbine.site.name}</a>, turbine <a href="/turbine/${this.options.repair.turbine._id}">${this.turbineStr}</a>, blade <a href="/blade/${this.options.repair.blade._id}">${this.bladeStr}</a> by ${this.currentUser.name}`,
          };

          this.ActivityLog.save(activityLogData)
            .$promise.then((activityLog) => {
              console.log(activityLog);
            })
            .catch((err) => {
              console.log("Error inserting activityLog", err);
            });
          this.$uibModalInstance.close(repair);
        })
        .catch((err) => {
          this.isDisabled = false;
          this.errors = {};
          this.stopSpin("spinner-1");
          this.handleError(err, form);
          this.toastr.error("Something went wrong.");
        });
    }

    createRepair(form) {
      console.log("in create repair: ", this.repair);
      this.Repair.save(this.repair)
        .$promise.then((repair) => {
          this.stopSpin("spinner-1");
          console.log("repair saved.");
          this.toastr.success("Repair added successfully.");
          var targetfinding = _.find(this.options.findings, {
            _id: this.repair.finding,
          });
          let uniqueReferenceNumber = "NA";
          if (targetfinding) {
            uniqueReferenceNumber = `<a href="/blade/${this.options.blade._id}/finding/${targetfinding._id}">${targetfinding.uniqueReferenceNumber}</a>`;
          }
          this.bladeStr = "";
          if (this.options.blade.make) {
            this.bladeStr += this.options.blade.make + " ";
          }
          if (this.options.blade.model) {
            this.bladeStr += this.options.blade.model;
          }
          if (this.options.blade.name) {
            this.bladeStr += this.options.blade.name;
          }

          this.turbineStr = "";
          if (this.options.blade.turbine.make) {
            this.turbineStr += this.options.blade.turbine.make;
          }
          if (this.options.blade.turbine.model) {
            this.turbineStr += this.options.blade.turbine.model;
          }
          if (this.options.blade.turbine.name) {
            this.turbineStr += this.options.blade.turbine.name;
          }
          const activityLogData = {
            site: this.options.blade.turbine.site._id,
            turbine: this.options.blade.turbine._id,
            blade: this.options.blade._id,
            finding: repair.data.finding,
            repair: repair.data._id,
            user: this.currentUser._id,
            source: "web",
            action: "create",
            type: "repair",
            sourceScreen: "finding",
            eventString: `Repair work order <a href="/repair/${repair.data._id}">${repair.data.woId}</a> was created for ${uniqueReferenceNumber} in wind farm <a href="/site/${this.options.blade.turbine.site._id}">${this.options.blade.turbine.site.name}</a>, turbine <a href="/turbine/${this.options.blade.turbine._id}">${this.turbineStr}</a>, blade <a href="/blade/${this.options.blade._id}">${this.bladeStr}</a> by ${this.currentUser.name}`,
          };

          this.ActivityLog.save(activityLogData)
            .$promise.then((activityLog) => {
              console.log(activityLog);
            })
            .catch((err) => {
              console.log("Error inserting activityLog", err);
            });
          this.$uibModalInstance.close(repair);
        })
        .catch((err) => {
          this.isDisabled = false;
          this.errors = {};
          this.stopSpin("spinner-1");
          this.handleError(err, form);
          this.toastr.error("Something went wrong.");
        });
    }

    startImageUpload(images, done) {
      this.totalImages = images.length;
      this.currentFileCount = 0;
      let skippedFiles = [];
      async.eachSeries(
        images,
        (image, callback) => {
          // Perform operation on file here.
          this.currentFileCount++;
          // upload image & then create finding
          this.uploadImage(image, (err, filePath) => {
            if (!err) {
              // TODO: find type from image & push in correct array
              // dataObj.finding.images.push(filePath);

              if (image.repairType === "REPAIR") {
                this.repair.images.push({
                  path: filePath,
                  isPrimary: this.currentFileCount === 1,
                });
              } else if (image.repairType === "CLOSE_UP") {
                this.repair.closeUpImages.push({
                  path: filePath,
                  isPrimary: this.currentFileCount === 1,
                });
              } else if (image.repairType === "CONDITION") {
                const conditionObj = _.find(
                  this.repair.defectProcedure.conditions,
                  { name: image.conditionName }
                );
                if (!conditionObj.images) {
                  conditionObj.images = [];
                }
                conditionObj.images.push({ path: filePath });
              } else if (image.repairType === "ShellPosition") {
                this.repair.elleApplication[
                  `shellPosition${image.shellPositionIndex}`
                ][`shellPositionImage${image.shellPositionImageIndex}`] =
                  filePath;
              }
              callback();
            } else {
              console.log("upload image failed:", err);
              callback(err);
            }
          });
        },
        (err) => {
          // if any of the file processing produced an error, err would equal that error
          if (err) {
            console.log("A file failed to upload");
            done(err);
          } else {
            done();
            //this.toastr.success(msg);
          }
        }
      );
    }

    getBladeFindings(turbineId, bladeId) {
      this.startSpin("spinner-1");
      const qry = { id: turbineId, controller: bladeId };
      this.Finding.get(qry)
        .$promise.then((response) => {
          if (response.data) {
            this.options.findings = response.data;
          }
          this.loading = false;
          this.stopSpin("spinner-1");
        })
        .catch((err) => {
          console.log(err);
          this.stopSpin("spinner-1");
          this.loading = false;
        });
    }

    handleError(err, form) {
      if (err && err.data && err.data.meta && err.data.meta.error_message) {
        if (
          err.data.meta.code &&
          err.data.meta.code != 422 &&
          !this.Util.isArray(err.data.meta.error_message)
        ) {
          this.serverError =
            err.data.meta.error_message || "Internal Server Error";
        } else {
          err.data.meta.error_message.forEach((errorMessage) => {
            for (let fieldName in errorMessage) {
              if (form[fieldName]) {
                form[fieldName].$setValidity("mongoose", false);
                this.errors[fieldName] = errorMessage[fieldName];
              }
            }
          });
        }
      } else {
        this.serverError = "Internal Server Error";
        console.log(err);
      }
    }

    openRepairDate() {
      this.repairDatePopup.opened = true;
    }

    removeImage(index, imageType, condition) {
      if (imageType === "IMAGE") {
        this.repair.images.splice(index, 1);
      } else if (imageType === "CLOSE_UP") {
        this.repair.closeUpImages.splice(index, 1);
      } else if (condition) {
        const conditionObj = _.find(this.repair.defectProcedure.conditions, {
          name: condition.name,
        });
        conditionObj.images.splice(index, 1);
      }
    }

    uploadImage(file, cb) {
      if (!file || !file.name) {
        cb({ success: false });
      }
      //console.log('image file:  ', file);
      var filename = file.name;
      var type = file.type;
      var query = {
        filename: filename,
        type: type,
      };
      this.isUploading = true;
      this.Image.signing({}, query).$promise.then((result) => {
        this.Upload.upload({
          url: result.url, //s3Url
          transformRequest: function (data, headersGetter) {
            var headers = headersGetter();
            delete headers.Authorization;
            return data;
          },
          fields: result.fields, //credentials
          method: "POST",
          file: file,
        })
          .progress((evt) => {
            this.uploadProgress = parseInt((100.0 * evt.loaded) / evt.total);
            // console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total));
          })
          .success((data, status, headers, config) => {
            this.isUploading = false;
            // file is uploaded successfully
            // console.log('file ' + config.file.name + 'is uploaded successfully. Response: ' + data);
            cb(null, result.url + "/" + result.fields.key);
          })
          .error((err) => {
            this.isUploading = false;
            console.log("err", err);
            this.toastr.success(
              "Image was not uploaded to cloud. please try again later or contact administrator"
            );
            cb(err);
          });
      });
    }

    cancelSave() {
      this.$uibModalInstance.dismiss("cancel");
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }

    openFindingList(e) {
      e.stopPropagation();
      $(".multiple-select-wrapper .list").toggle("slideDown");
    }

    selectOption(finding) {
      this.selectedFinding = finding;
      this.repair.finding = finding._id;
      $(".multiple-select-wrapper .list").toggle("slideUp");
    }

    addMaterial(materials) {
      let newItemNo = materials.length + 1;
      materials.push({ colId: "col" + newItemNo });
    }

    removeMaterial(materials, index) {
      materials.splice(index, 1);
    }
  }

  angular
    .module("windmanagerApp")
    .controller("ManageRepairModalCtrl", ManageRepairModalCtrl);
})();
